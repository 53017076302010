import React from 'react'


function Footer() {
  return (
    <div id='footer' className='my-3 text-muted'>
      Copyright © 2023 All Rights Reserved
    </div>
  )
}

export default Footer
